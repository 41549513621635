<template>
<div>
    <b-container>
        <b-row>
            <b-col lg="12" md="12" sm="12" cols="12">
                <h4 class="font-bold mt-4">用户协议</h4>
                <p class="font-bold">一、特别提示</p>
                <p class="">1. “传奇云游戏”所提供的各项服务的所有权和运作权均归于上海天游软件有限公司（以下简称“天游软件”）天游软件 在此特别提醒：请您务必审慎阅读、充分理解本用户协议（下称“本协议”）各条款内容，特别是免除或者限制责任的条款。本协议是您使用传奇云游戏平台相关服务所订立的协议。一旦您使用传奇云游戏任何服务，视为您已阅读并同意本协议所有条款。</p>
                <p class="">2. 本协议包括本服务条款及其修改、不时发布的与 “传奇云游戏服务”任何相关的操作规则、注意事项，还包括文化部根据 《网络游戏管理暂行办法》 （文化部令第49号）制定的《网络游戏服务格式化协议必备条款》。</p>
                <p class="">3. 如果您未满18周岁，请在法定监护人的陪同下阅读本协议，并特别注意未成年人使用条款。</p>
                <p class="">4. 传奇云游戏向用户提供游戏及服务属商业行为。您若接受传奇云游戏提供的游戏产品或服务可能会被收取费用。所有游戏产品或服务内容、收费项目、收费标准、收费方式、退订方式等将会在扣费前向您提示。请您在接受传奇云游戏的游戏产品或服务前充分考虑。</p>
                <p class="">5.本协议约定天游软件与用户之间关于本产品软件服务（以下简称 “服务”或“本产品”）的权利义务。本《协议》之规定如有更改或出台实施细则，将会在本网站的相关页面上予以公告。如果用户不同意该等修改内容或实施细则，用户可以主动取消本产品服务。如果用户继续使用本服务，则视为接受协议的变更或实施细则 。</p>
                <p class="font-bold">二、知识产权</p>
                <p class="">传奇云游戏相关的所有著作权、专利权、商标、商业秘密及其它任何权利和权益，均属传奇云游戏或其原始授权人所有。非传奇云游戏或原始授权人的同意，任何人（包括不限于用户）均不得擅自下载、重制、传输、改作、编辑于任何为线上游戏目的以外的使用或任何以获利为目的的使用，否则应负所有法律责任。</p>
                <p class="font-bold">三 、 账号注册</p>
                <p class="">1. 用户在使用本服务时， 须先注册 一个账号 ， 您申请注册传奇云游戏时，应保证所提供的个人身份资料信息真实、完整、有效，依据法律规定和必备条款约定对所提供的信息承担相应的法律责任。传奇云游戏 有权对用户资料的真实性进行审查，并应积极地采取技术与管理等合理措施保障用户账号的安全、有效。</p>
                <p class="">2. 一般情况下，您可随时浏览、修改自己提交的基础信息。但出于安全性和身份识别考虑，您如需修改账户信息及身份信息时请提供有效个人资料进行核实。如因用户提交的修改信息不及时或不准确而产生的一切后果和损失由用户负责。</p>
                <p class="font-bold">四、 账号说明</p>
                <p class="">1. 您一旦注册成功，便成为传奇云游戏的合法用户，将得到一个帐号和密码。您应采取合理措施维护帐号和密码的安全。您需对利用该帐号和密码所进行的一切活动承担全部责任。</p>
                <p class="">用户帐号和密码遭到未授权的使用或发生其他任何安全问题，您应立即通知传奇云游戏客服。对于用户长时间未使用的帐号，传奇云游戏有权予以关闭。</p>
                <p class="">2. 若因用户自身原因导致账号、密码遭他人非法使用，造成的损失本产品不予承担。用户自身原因包括但不限于：任意向第三者透露账号和密码及其他登录信息；多人共享同一个账号；安装非法或来路不明的程序等。</p>
                <p class="">3. 若您发现账号或密码被他人非法使用或有异常使用的情形，应立即联系我方，并有权通知传奇云游戏暂停该账号的登录和使用，须注意，您在联系我方要求暂停账号使用时应提供与注册身份信息相一致的个人有效身份信息。</p>
                <p class="">4. 账号封禁</p>
                <p class="">您在使用传奇云游戏相关游戏服务过程中有如下行为的，传奇云游戏将视情节严重程度，依据本协议及相关游戏规则的规定，对您做出暂时或永久性地禁止登录、删除游戏账号及游戏数据、删除相关信息等处理措施，情节严重的将移交有关行政管理机关给予行政处罚，或者追究您的刑事责任。</p>
                <p class="">若因如下任意一种或多种行为而导致您的账号被封禁，本产品不承担任何责任：</p>
                <p class="pl_30">(1) 有违反本服务协议的行为；</p>
                <p class="pl_30">(2) 违反中国的法律、法规；</p>
                <p class="pl_30">(3) 以任何方式破坏传奇云游戏的游戏或影响传奇云游戏中的游戏服务的正常进行，包括但不限于：利用编译或反编译程序修改游戏资料、使用异常的方法登录游戏、使用网络加速器等外挂软件或机器人程式等恶意破坏服务设施、扰乱正常服务秩序的行为。</p>
                <p class="pl_30">(4) 传播非法言论或不当信息；</p>
                <p class="pl_30">(5) 盗取他人游戏账号、游戏物品；</p>
                <p class="pl_30">(6) 私自进行游戏账号交易；</p>
                <p class="pl_30">(7) 私自进行游戏道具、游戏装备、游戏币等交易；</p>
                <p class="pl_30">(8) 恶性破坏我们的服务设施；</p>
                <p class="pl_30">(9) 不服从客服劝导、对客服辱骂。予以关闭聊天功能、封账号的处罚。</p>
                <p class="pl_30">(10) 其他在行业内被广泛认可的不当行为，无论是否已经被本协议或游戏规则明确列明。</p>
                <p class="">5. 严禁用户利用线上游戏的机会做与游戏无关的行为，包括但不限于妨害他人名誉或隐私权；或使用自己、匿名或冒用他人或传奇云游戏名义散播诽谤、不实、威胁、不雅、猥亵、不法、攻击性或侵害他人权利的消息或文字，传播色情或其它违反社会公德的言论；传输或散布计算机病毒；从事广告或贩卖商品；从事不法交易或张贴虚假不实或引人犯罪的信息；或任何违反中华人民共和国法律法规和政策的行为，亦不得利用线上游戏的机会，与其它用户或他人进行非法的交涉或对话。</p>
                <p class="">6. 除非法律允许或传奇云游戏书面许可，您不得从事下列行为：</p>
                <p class="pl_30">(1) 删除游戏软件及其副本上关于著作权的信息；</p>
                <p class="pl_30">(2) 对游戏软件进行反向工程、反向汇编、反向编译或者以其他方式尝试发现软件的源代码；</p>
                <p class="pl_30">(3) 对游戏软件进行扫描、探查、测试，以检测、发现、查找其中可能存在的BUG或弱点；</p>
                <p class="pl_30">(4) 对游戏软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经合法授权的第三方工具/服务接入软件和相关系统；</p>
                <p class="pl_30">(5) 修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论上述行为是否为商业目的；</p>
                <p class="pl_30">(6) 通过非传奇云游戏开发、授权的第三方软件、插件、外挂、系统，使用传奇云游戏，或制作、发布、传播非传奇云游戏开发、授权的第三方软件、插件、外挂、系统；</p>
                <p class="pl_30">(7) 对游戏中传奇云游戏拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；</p>
                <p class="pl_30">(8) 建立有关传奇云游戏的镜像站点，或者进行网页（络）快照，或者利用架设服务器等方式，为他人提供与传奇云游戏完全相同或者类似的服务；</p>
                <p class="pl_30">(9) 将传奇云游戏的任意部分分离出来单独使用，或者进行其他的不符合本协议的使用；</p>
                <p class="pl_30">(10) 使用传奇云游戏软件的名称、商标或其它知识产权；</p>
                <p class="pl_30">(11) 其他未经传奇云游戏明示授权的行为。</p>
                <p class="font-bold">五、账号使用与保管</p>
                <p class="">根据文化部必备条款的约定，传奇云游戏有权审查用户注册所提供的身份信息是否真实、有效，并应积极地采取技术与管理等合理措施保障用户账号的安全、有效；用户有义务妥善保管其账号及密码，并正确、安全地使用其账号及密码。任何一方未尽上述义务导致账号密码遗失、账号被盗等情形而给用户和他人的民事权利造成损害的，应当承担由此产生的法律责任。</p>
                <p class="">1．用户对登录后所持账号产生的行为依法享有权利和承担责任。</p>
                <p class="">2．用户发现其账号或密码被他人非法使用或有使用异常的情况的，应及时根据传奇云游戏公布的处理方式通知传奇云游戏客服，并有权通知传奇云游戏采取措施暂停该账号的登录和使用。</p>
                <p class="">3．传奇云游戏根据用户的通知采取措施暂停用户账号的登录和使用的，传奇云游戏 应当要求用户提供并核实与其注册身份信息相一致的个人有效身份信息。</p>
                <p class="pl_30">(1) 传奇云游戏核实用户所提供的个人有效身份信息与所注册的身份信息相一致的，应当及时采取措施暂停用户账号的登录和使用。</p>
                <p class="pl_30">(2) 传奇云游戏违反3-（1）款项的约定，未及时采取措施暂停用户账号的登录和使用，因此而给用户造成损失的，应当承担其相应的法律责任。</p>
                <p class="pl_30">(3) 用户没有提供其个人有效身份证件或者用户提供的个人有效身份证件与所注册的身份信息不一致的，传奇云游戏有权拒绝上述请求。</p>
                <p class="">4．用户为了维护其合法权益，向传奇云游戏提供与所注册的身份信息相一致的个人有效身份信息时，传奇云游戏应当为用户提供账号注册人证明、原始注册信息等必要的协助和支持，并根据需要向有关行政机关和司法机关提供相关证据信息资料。</p>
                <p class="font-bold">六、 资费政策</p>
                <p class="">有关产品和服务的收费标准，本产品上会明确显示，用户进行消费时本产品需给予明示。传奇云游戏将有权决定本产品所提供的产品和服务的资费标准和收费方式，所有的消费以当时的资费为准，调价将不另行通知。</p>
                <p class="">用户对账号进行的一切充值、消费行为时请按需要充值，充值、消费行为一旦成功，除传奇云游戏终止服务以外，所有充值金额不予退还。关于服务终止，详见七. 4 《服务中断与停止》。</p>
                <p class="font-bold">七、 服务内容及许可说明</p>
                <p class="">1. 本服务的具体内容由本产品依照实际情况提供，包括但不限于授权用户通过其账号进行游戏、保存存档、充值、视频录制、游戏测评、游戏推荐、享受各种会员服务等。本产品会根据市场策略变化而变更所提供的服务内容，并以公告等方式通知用户。</p>
                <p class="">2. 在使用本产品时出现任何违反许可限制的行为，都将被认为是侵害传奇云游戏的游戏版权。您同意在任何情况下您均不得：</p>
                <p class="pl_30">(1) 使用作弊软件、机器人程序软件、黑客程序软件、游戏增强程序或其它未经授权的第三方软件修改或干扰本产品服务、游戏或游戏体验，过度开发或滥用游戏资源或服务，影响游戏正常秩序与公平。</p>
                <p class="pl_30">(2) 利用本产品服务或者游戏或其中包含的任何内容进行商业牟利，包括但不限于：</p>
                <p class="">a. 在未经运营方和/或传奇云游戏书面同意的情况下，在网吧、电脑游戏中心或者其它商业场所内进行该类活动；</p>
                <p class="">b. 传播或者协助传播商业广告或者实施类似的教唆行为.</p>
                <p class="pl_30">(3) 使用未经授权的第三方软件拦截、挖取或以其它方式从本产品中收集信息。</p>
                <p class="pl_30">(4) 通过任何未经运营方和/或传奇云游戏明确认可的方式，修改游戏或本产品服务的任何组成文件或导致前述文件遭到修改。</p>
                <p class="pl_30">(5) 为了某种目的而使用任何手段为本产品服务搭建主机、提供或开发配对服务，或者拦截、模仿或者重定向运营方和/或运营方所使用的通信协议， 包括但不限于通过互联网、联网对战或内容聚合网络等形式来进行未经授权的游戏行为。 </p>
                <p class="pl_30">(6) 协助、创建或维护任何未经授权的连接到本产品服务的连接，包括但不限于：</p>
                <p class="pl_30">(7) 任何连接到任意一个模仿或试图模仿本产品服务或游戏的未经授权的服务器；</p>
                <p class="pl_30">(8) 使用未被传奇云游戏和/或运营方明确认可的程序或工具的任意连接。</p>
                <p class="pl_30">(9) 干扰以及协助干扰本产品服务或者破坏游戏正常运行的行为，将可能触犯刑法和民法。</p>
                <p class="pl_30">(10) 出于任何目的，对游戏或者本产品服务实施或者意图实施反向工程、反向编译或者反汇编。</p>
                <p class="">3. 用户义务</p>
                <p class="pl_30">(1) 用户使用本产品服务，则意味用户已同意本条款以及适用于本产品服务的游戏的其他公开规则；</p>
                <p class="pl_30">(2) 自行提供与服务有关的设备(如电脑、机顶盒及其他与接入互联网有关的装置)并承担所需的费用(如为接入互联网而支付的电话费及上网费)；</p>
                <p class="pl_30">(3) 用户应确保绑定账号的真实性与安全性，如因用户账号信息错误导致用户无法正常使用本产品，传奇云游戏不承担任何责任。</p>
                <p class="">4. 服务中断与停止</p>
                <p class="pl_30">(1) 为了网站的正常运行，传奇云游戏需要定期或不定期地对服务器进行停机维护因此类情况而造成的正常服务中断、停止，希望用户予以理解，本产品有义务提前告知用户，并尽力将中断、停止时间限制在最短时间内，因此造成的不便和损失，本产品不承担相关责任；</p>
                <p class="pl_30">(2) 本产品提供均为虚拟服务（例如会员），相关虚拟物品仅在服务运营期内有效，服务停止时，虚拟物品将随服务停止运营而消失，用户一经获得则不能再以任何形式退还给本产品；</p>
                <p class="pl_30">(3) 若本产品部分服务中断（如游戏下架），则本产品以虚拟货币的形式对玩家进行补偿。对于购买有限时长的用户，若在服务中断时仍拥有游戏时长，则将退还剩余时长对应比例的虚拟物品；</p>
                <p class="pl_30">(4) 对于用户已经购买但尚未使用的虚拟物品（仅指会员），本产品将以法定货币方式或话费的方式退还用户；</p>
                <p class="pl_30">(5) 关于虚拟物品的退还，本产品将在终止服务公告中明确申请退还的期限和具体退还政策。届时用户应依照本产品公布的政策在时限内办理退还申请，逾期则视为用户自动放弃该权利；</p>
                <p class="pl_30">(6) 对于不符合退还政策的虚拟物品，本产品有权拒绝退还。</p>
                <p class="font-bold">八、 用户个人隐私信息保护</p>
                <p class="">1. 个人隐私信息是指涉及用户个人身份或个人隐私的信息，如用户手机号码、设备识别码、IP地址、地理位置等。非个人隐私信息是指用户对本产品的操作状态以及使用习惯等明确且客观反映在本产品服务器端的基本记录信息、个人隐私信息范围外的其它普通信息，以及用户同意公开的上述隐私信息。</p>
                <p class="">2. 本产品未经用户同意不向任何第三方公开、 透露用户个人隐私信息。但以下特定情形除外：</p>
                <p class="pl_30">(1) 本产品根据法律法规规定或有权机关的指示提供用户的个人隐私信息;</p>
                <p class="pl_30">(2) 由于用户将其用户密码告知他人或与他人共享注册帐户与密码，由此导致的任何个人信息的泄漏，或其他非因本产品原因导致的个人隐私信息的泄露；</p>
                <p class="pl_30">(3) 用户自行向第三方公开其个人隐私信息；</p>
                <p class="pl_30">(4) 用户与本产品及合作单位之间就用户个人隐私信息的使用公开达成约定，本产品因此向合作单位公开用户个人隐私信息；</p>
                <p class="pl_30">(5) 任何由于黑客攻击、电脑病毒侵入及其他不可抗力事件导致用户个人隐私信息的泄露。</p>
                <p class="">3. 用户使用本产品服务，则视为同意本产品可在以下事项中使用用户的个人隐私信息：</p>
                <p class="pl_30">(1) 本产品向用户及时发送重要通知，如软件更新、本协议条款的变更；</p>
                <p class="pl_30">(2) 本产品内部进行审计、数据分析和研究等，以改进本产品的产品、服务和与用户之间的沟通；</p>
                <p class="pl_30">(3) 依本协议约定，本产品管理、审查用户信息及进行处理措施；</p>
                <p class="pl_30">(4) 适用法律法规规定的其他事项；</p>
                <p class="pl_30">(5) 除上述事项外，如未取得用户事先同意，本产品不会将用户个人隐私信息使用于任何其他用途。</p>
                <p class="">4. 用户同意接受本产品发送的商业营销类短信或电话。</p>
                <p class="font-bold">九、 数据存储</p>
                <p class="">1. 用户可自行退订服务，相关数据逾期将会删除，本产品对此不承担责任。</p>
                <p class="">2. 传奇云游戏可以根据实际情况自行决定用户在本产品服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。用户可根据自己的需要自行备份本产品服务中的相关数据。</p>
                <p class="">3. 如用户在使用本服务过程中或更新协议表示不再认同或接受本协议，则停止使用本产品或本产品服务终止，传奇云游戏可以从服务器上永久地删除用户的数据。本产品服务停止、终止后，传奇云游戏没有义务向此类用户返还任何数据。</p>
                <p class="font-bold">十、 违约赔偿</p>
                <p class="">1. 用户同意保障和维护传奇云游戏本产品及其他用户的利益，如因用户违反有关法律、法规或本协议项下的任何条款而导致本产品或其关系企业、受雇人、受托人、代理人或/及其它相关履行辅助人或任何其他第三人因此而受到损害或支出费用（包括但不限于由法律诉讼、行政程序等所支出的诉讼费用、律师费用、实际遭受损失的费用等），用户应负担损害赔偿责任。</p>
                <p class="">2. 本产品对于用户使用其服务或无法使用网络所导致的任何直接、间接、衍生的损害或所失利益不负任何损害赔偿责任。</p>
                <p class="font-bold">十一、 其他</p>
                <p class="">1. 本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和传奇云游戏之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交上海市徐汇区人民法院管辖。</p>
                <p class="">2. 本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。</p>
            </b-col>
        </b-row>
    </b-container>
</div>
</template>

<script>
export default {
    name: "Agreement",
    components: {},
    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped>
.font-bold {
    font-weight: bold;
}

.pl_30 {
    padding-left: 30px;
}
</style>
